import React, { useState, useEffect } from 'react';
import './Forgot.css';
import { forgotPassword } from '../../Common/SharedApis/Auth.tsx'; // Import the forgotPassword function
import { toast } from 'react-toastify';
import { useLoading } from '../../contexts/LoadingContext.tsx';

const Forgot = () => {
  const [email, setEmail] = useState(''); // State for email input
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [timer, setTimer] = useState(0); // State for managing the timer
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State for disabling the button
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    let interval;
    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  const handleRequestCode = async () => {
    try {
      setIsLoading(true);
      await forgotPassword(email); // Call forgotPassword function
      setSuccess('Password reset link has been sent to your email.');
      setError('');
      toast.success('Password reset link has been sent to your email.'); // Optionally use toast for success messages
      setTimer(45); // Start the timer at 45 seconds
      setIsButtonDisabled(true); // Disable the button
    } catch (error) {
      setError(error.message || 'An unexpected error occurred.');
      setSuccess('');
      toast.error(error.message || 'An unexpected error occurred.'); // Optionally use toast for error messages
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-header">
        <button className="back-button" onClick={() => window.history.back()}>
          &larr;
        </button>
      </div>
      <h2 className="forgot-password-title">Retrieve password</h2>
      <form className="forgot-password-form" onSubmit={(e) => { e.preventDefault(); handleRequestCode(); }}>
        <div className="input-group">
          <label>Email</label>
          <input
            type="email"
            placeholder="Please enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          className="request-code-button"
          disabled={isButtonDisabled || isLoading} // Disable button if it's disabled or loading
        >
          {isButtonDisabled ? `Resend Code in ${timer}s` : 'Request Code'} 
        </button>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </form>
    </div>
  );
};

export default Forgot;
