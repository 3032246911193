import React from 'react';
import './StatusBar.css'; // Add styles for StatusBar

const StatusBar = ({ message, show, onClose }) => {
  if (!show) return null;

  return (
    <div className="status-bar">
      <span>{message}</span>
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default StatusBar;
