// src/components/RealTimeData.tsx
import React, { useEffect } from 'react';

interface WebSocketData {
  p: string; // Assuming 'p' is the price in string format
}

interface RealTimeDataProps {
  symbols: string[];
  onDataUpdate: (data: WebSocketData, symbol: string) => void;
}

const RealTimeData: React.FC<RealTimeDataProps> = ({ symbols, onDataUpdate }) => {
  useEffect(() => {
    const sockets = symbols.map((symbol) => {
      const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@trade`);

      ws.onopen = () => {
        console.log(`WebSocket connected for ${symbol}`);
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data) as WebSocketData;
          onDataUpdate(data, symbol);
        } catch (error) {
          console.error(`Error parsing WebSocket message for ${symbol}:`, error);
        }
      };

      ws.onerror = (error) => {
        console.error(`WebSocket error for ${symbol}`, error);
      };

      ws.onclose = () => {
        console.log(`WebSocket disconnected for ${symbol}`);
      };

      return ws;
    });

    // Cleanup function to close all WebSocket connections when component unmounts
    return () => {
      sockets.forEach((ws) => ws.close());
    };
  }, [symbols, onDataUpdate]);

  return null;
};

export default RealTimeData;
