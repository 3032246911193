import React, { useState, useEffect } from 'react';
import './BindPocketAddress.css';
import { useNavigate, useParams } from 'react-router-dom';
import { bindPocketAddress } from '../../../Common/SharedApis/DespositMethods.tsx';
import { useLoading } from '../../../contexts/LoadingContext.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

const BindPocketAddress: React.FC = () => {
    const navigate = useNavigate();
    const { digitalCoinId } = useParams<{ digitalCoinId: string }>();
    const [pocketAddr, setPocketAddr] = useState('');
    const { isLoading, setIsLoading } = useLoading();

    useEffect(() => {
        console.log('Digital Coin ID from URL:', digitalCoinId);
    }, [digitalCoinId]);

    const handleBackClick = () => {
        navigate(-1); 
    };

    const handleSubmit = async () => {
        if (!pocketAddr) {
            toast.error('Pocket address is required. Please enter the address.'); // Show error if field is empty
            return;
        }

        const data = {
            digitalCoinId: digitalCoinId || '', // Use the ID from URL or fallback to '1'
            address: pocketAddr,
            userId: '2', // Hard-coded userId
            lastModify: new Date().toISOString(), // Current date-time
        };

        console.log('Submitting data:', data);

        setIsLoading(true); // Set loading state to true

        try {
            const response = await bindPocketAddress(data);
            console.log('Response:', response);
            toast.success('Pocket address bound successfully!'); // Show success notification
            setPocketAddr(''); // Clear the input field after successful submission
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to bind pocket address. Please try again.'); // Show error notification
        } finally {
            setIsLoading(false); // Set loading state to false after the operation
        }
    };
  
    return (
        <div className="bind-pocket-address">
            <div className="header">
                <button className="back-button" onClick={handleBackClick}>←</button>
                <h2>Bind Pocket Address</h2>
            </div>
            <p className="warning-text">
                For your fund security, please confirm the digital currency type before
                binding the digital wallet address to avoid fund loss.
            </p>
            <div className="currency-info">
                <div className="currency-details">
                    <img
                        src="https://via.placeholder.com/40" // Replace with actual BTC icon URL
                        alt="BTC Icon"
                        className="currency-icon"
                    />
                    <span className="currency-name">BTC(BTC20)</span>
                </div>
                <span className="chain-name">Chain:BTC20</span>
            </div>
            <div className="input-container">
                <label htmlFor="pocket-addr" className="input-label">
                    Pocket Addr
                </label>
                <input
                    type="text"
                    id="pocket-addr"
                    placeholder="Please Enter Pocket Address"
                    className="pocket-addr-input"
                    value={pocketAddr}
                    onChange={(e) => setPocketAddr(e.target.value)}
                    required // HTML5 required attribute
                />
            </div>
            <button className="submit-button" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
            </button>
        </div>
    );
};

export default BindPocketAddress;
