// src/components/Loader.js
import React from 'react';
import './Loader.css'; // Assuming you'll style it separately

const Loader = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="loader-overlay">
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
