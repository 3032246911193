import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../Common/SharedApis/Auth.tsx'; // Adjust the import path as needed
import { useLoading } from '../../contexts/LoadingContext.tsx';
import { faL } from '@fortawesome/free-solid-svg-icons';
import './ResetPassword.css';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token and email from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
        setError('Passwords do not match.');
        return;
    }

    try {
        setIsLoading(true);
        await resetPassword({ Email: email ?? "", Token: token ?? "", NewPassword: password });
        setSuccess('Your password has been reset successfully.');
        toast.success('Your password has been reset successfully.');
        setError('');
        setTimeout(() => {
            navigate('/login');
        }, 2000);
    } catch (error) {
        setError(error.message || 'An unexpected error occurred. Please try again.');
        setSuccess('');
        toast.error(error.message || 'An unexpected error occurred. Please try again.'); // Display error using toast
    }
    finally{
        setIsLoading(false);
    }
};


  return (
    <div className="reset-password-container">
      <h2>Reset Your Password</h2>
      <form onSubmit={handleResetPassword}>
        <div className="input-group">
          <label>New Password</label>
          <input
            type="password"
            placeholder="Enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Reset Password</button>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </form>
    </div>
  );
};

export default ResetPassword;
