import React from 'react';
import './FeaturedSection.css';
import { FaArrowRight } from 'react-icons/fa';

const FeaturedSection = () => {
  return (
    <div className="featured-section">
      <div className="featured-item">
        <div className="featured-left">
          <img
            src="/path-to-image/lockup-icon.png"
            alt="Lock-up Mining"
            className="featured-icon"
          />
          <div className="featured-text">
            <h3>Lock-up mining</h3>
            <p>One-stop financial services</p>
          </div>
        </div>
        <div className="featured-arrow-button">
          <FaArrowRight />
        </div>
      </div>

      <div className="featured-item">
        <div className="featured-left">
          <h3>Market Order</h3>
        </div>
        <div className="featured-more">
          <span>More</span>
          <div className="featured-arrow-button">
            <FaArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedSection;
