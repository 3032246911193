// src/components/CryptoDashboard.js
import React, { useState } from 'react';
import TradingViewChart from './Trading.tsx';
import RealTimeData from './RealTimeData.tsx';

const CryptoDashboard = () => {
  const [prices, setPrices] = useState({});

  const handleDataUpdate = (data, symbol) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [symbol]: data.p,
    }));
  };

  const symbols = ['btcusdt']; // List of symbols you want to track

  return (
    <div>
      <h1>Crypto Dashboard</h1>
      <div>
        {symbols.map((symbol) => (
          <div key={symbol}>
            <h2>{symbol.toUpperCase()}</h2>
            <TradingViewChart symbol={symbol} />
            <div>Latest Price: {prices[symbol] || 'Loading...'}</div>
          </div>
        ))}
      </div>
      <RealTimeData symbols={symbols} onDataUpdate={handleDataUpdate} />
    </div>
  );
};

export default CryptoDashboard;
