import React, { useEffect, useRef, useState } from 'react';
import './Trading.css'; // Create this CSS file to style the component

const TradingViewChart: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const [priceData, setPriceData] = useState({
    lastPrice: '',
    todayChange: ''
  });

  useEffect(() => {
    if (chartContainerRef.current) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;
      script.onload = () => {
        if (window.TradingView && window.Datafeeds && chartContainerRef.current) {
          new window.TradingView.widget({
            autosize: true,
            symbol: symbol || 'BTCUSDT',
            interval: 'D',
            container_id: chartContainerRef.current.id,
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
            library_path: '/charting_library/',
            locale: 'en',
            disabled_features: ['header_widget'],
            enabled_features: ['study_templates'],
            theme: 'light',
          });
        }
      };
      document.body.appendChild(script);

      // Cleanup function to remove script
      return () => {
        document.body.removeChild(script);
      };
    }

    // Fetch Bitcoin price data (replace with your actual data fetching logic)
    fetch('https://api.example.com/bitcoin-price')
      .then(response => response.json())
      .then(data => {
      console.log('data',data);

        setPriceData({
          lastPrice: `$${data.lastPrice.toFixed(2)}`,
          todayChange: `${data.todayChange.toFixed(2)}%`
        });
      })
      .catch(error => console.error('Error fetching Bitcoin price data:', error));
  }, [symbol]);

  return (
    <div>
      <div className="bitcoin-price-container">
        <table>
          <thead>
            <tr>
              <th>Products</th>
              <th>Last Price</th>
              <th>Today Change</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><img src="bitcoin-icon.png" alt="Bitcoin" className="bitcoin-icon" /> Bitcoin</td>
              <td>{priceData.lastPrice}</td>
              <td className={parseFloat(priceData.todayChange) < 0 ? 'negative' : 'positive'}>
                {priceData.todayChange}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="tradingview_chart" ref={chartContainerRef} style={{ height: '400px' }} />
    </div>
  );
};

export default TradingViewChart;
