import * as signalR from '@microsoft/signalr';
import apiBaseUrl from '../Common/ApiBaseUrl.tsx';
const createSignalRConnection = (token: string) => {
  if (!token) {
    console.warn("No token found. SignalR connection will not be established.");
    return null; // Prevent creating the connection if there's no token
  }

  const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${apiBaseUrl.signalRBaseUrl}/notify`, {
      accessTokenFactory: () => token,  // Pass the token here
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  return newConnection;
};

export default createSignalRConnection;
