import React from 'react';
import BottomNav from './BottomNav.tsx';  
function Footer() {
    return (
        <div>
            {/* <h1>Footer</h1> */}
            {/* Add your home page content here */}
          
        </div>
    );
}

export default Footer;
