import React, { useEffect, useRef, useState } from 'react';
import './Chat.css';
import { useSignalR } from '../../contexts/SignalRProvider.tsx';
import { getChatRoomId, getMessages, sendMessageToAdmin, sendMessageToUser } from '../../Common/SharedApis/ChatSystem.tsx';
import Semaphore from 'semaphore';
import debounce from 'lodash/debounce';

interface Message {
  text: string;
  sender: string;
  time: string;
}

const Chat: React.FC = () => {
  const signalRContext = useSignalR();
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState<string>('');
  const [userId, setUserId] = useState<string | null>(null);
  const [chatRoomId, setChatRoomId] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const chatBodyRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const prevScrollTopRef = useRef<number>(0);

  const semaphore = Semaphore(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedChatRoomId = localStorage.getItem('chatRoomId');

    if (storedUserId) {
      setUserId(storedUserId);
      if (storedChatRoomId) {
        setChatRoomId(parseInt(storedChatRoomId));
        loadMessages(parseInt(storedChatRoomId), 1);
      } else {
        getChatRoomId(storedUserId)
          .then((chatRoomId) => {
            setChatRoomId(chatRoomId);
            localStorage.setItem('chatRoomId', chatRoomId.toString());
            loadMessages(chatRoomId, 1);
          })
          .catch((error) => console.error('Error fetching chat room ID:', error));
      }
    }
  }, []);

  useEffect(() => {
    if (signalRContext && signalRContext.connection) {
      const { connection } = signalRContext;

      connection.on('BrodCastMessageToUser', (sender: string, text: string) => {
        const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        setMessages((prevMessages) => [
          ...prevMessages,
          { text, sender, time },
        ]);
        scrollToBottom();  // Scroll to bottom when a new message is received
      });

      return () => {
        connection.off('BrodCastMessageToUser');
      };
    }
  }, [signalRContext]);

  const loadMessages = async (chatRoomId: number, page: number) => {
    setIsLoading(true);
    semaphore.take(async () => {
      try {
        setLoading(true);
        const fetchedMessages = await getMessages(chatRoomId, page);
        if (fetchedMessages.length < 20) {
          setHasMore(false);
        }

        const chatBody = chatBodyRef.current;
        const previousScrollHeight = chatBody?.scrollHeight ?? 0;

        setMessages(prevMessages => [
          ...fetchedMessages.reverse().map(m => ({
            text: m.message,
            sender: m.senderId,
            time: new Date(m.sentAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          })),
          ...prevMessages,
        ]);
        setIsLoading(false);
        setPageNumber(page + 1);

        if (chatBody && !isInitialLoad) {
          chatBody.scrollTop = chatBody.scrollHeight - previousScrollHeight;
        }

        if (isInitialLoad) {
          scrollToBottom();
          setIsInitialLoad(false);
        }
      } catch (error) {
        console.error('Error loading messages:', error);
      } finally {
        setLoading(false);
        semaphore.leave();
      }
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const handleScroll = debounce(() => {
    const chatBody = chatBodyRef.current;
    if (!chatBody || isLoading) return;

    const isScrollingUp = chatBody.scrollTop < chatBody.scrollHeight - chatBody.clientHeight;

    if (isScrollingUp && hasMore) {
      setPageNumber(pageNumber + 1);
      loadMessages(3,pageNumber);
    }
  }, 300);


  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    if (inputMessage.trim() && userId) {
      const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      setMessages([...messages, { text: inputMessage, sender: userId, time }]);
      setInputMessage('');

      try {
        if (chatRoomId) {
          await sendMessageToUser({ SenderId: userId, ChatRoomId: chatRoomId, Message: inputMessage });
        } else {
          await sendMessageToAdmin({ SenderId: userId, Message: inputMessage });
        }
        scrollToBottom();  // Scroll to bottom when a new message is sent
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2>Chat</h2>
      </div>
      <div className="chat-body" ref={chatBodyRef} onScroll={handleScroll}>
        {loading && <div className="loading-message">Loading...</div>}
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.sender === userId ? 'user-message' : 'admin-message'
            }`}
          >
            {message.text}
            <div className="chat-time">{message.time}</div>
          </div>
        ))}
        <div ref={scrollRef} />
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Chat;
