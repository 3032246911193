import React, { useEffect, useState } from 'react';
import './PocketAddr.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../../contexts/LoadingContext.tsx';

import { walletadressbyuserid } from '../../../Common/SharedApis/DespositMethods.tsx'; // Adjust import as necessary

const PocketAddr: React.FC = () => {
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useLoading();
    const [addresses, setAddresses] = useState<any[]>([]); // Use the appropriate type

    useEffect(() => {
        const fetchAddresses = async () => {
            setIsLoading(true);  // Start loading
            const userId = 2; // Adjust as needed
            const data = await walletadressbyuserid(userId);

            if (data) {
                setAddresses(data);
            }
            setIsLoading(true);  // Start loading
        
                setIsLoading(false);  // Stop loading
              
        };

        fetchAddresses();
    }, []);

    const handleBackClick = () => {
        navigate('/home');
    };

    const handleMethodClick = (digitalCoinId: number) => {
        console.log('digitalCoinId', digitalCoinId);
        navigate(`/BindPocketAddress/${digitalCoinId}`);
    };

    const availableCoins = [
        { id: 1, coinName: 'USDT', logo: '/images/usdt-logo.png' },
        { id: 2, coinName: 'BTC', logo: '/images/btc-logo.png' },
        { id: 3, coinName: 'ETH', logo: '/images/eth-logo.png' },
        { id: 4, coinName: 'USDC', logo: '/images/usdc-logo.png' },
    ];

    return (
        <div className="pocket-addr-container">
            <div className="header">
                <button className="back-button" onClick={handleBackClick}>←</button>
                <h1>Pocket Addr</h1>
            </div>

            <div className="wallet-list">
                {addresses.map((addr) => (
                    <div key={addr.id} className="wallet-item">
                        <img src={addr.logo} alt={`${addr.coinName} logo`} className="coin-logo" />
                        <div className="wallet-info">
                            <span className="coin-name">
                                {addr.coinName} ({addr.chainName})
                            </span>
                            <span className="wallet-address">{addr.address}</span>
                        </div>
                        <span className="chain-name">{addr.chainName}</span>
                        <FontAwesomeIcon icon={faEllipsisV} className="more-options-icon" />
                    </div>
                ))}
            </div>

            <div className="available-coins">
                {availableCoins.map((coin) => (
                    <div key={coin.id} className="coin-item" onClick={() => handleMethodClick(coin.id)}>
                        <img src={coin.logo} alt={`${coin.coinName} logo`} className="coin-logo" />
                        <span className="coin-name">{coin.coinName}</span>
                        <FontAwesomeIcon icon={faPlus} className="add-icon" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PocketAddr;
