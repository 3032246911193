import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TimerPage.css';

const TimerPage: React.FC = () => {
    const location = useLocation();
    const { state } = location;
    const initialTime = state?.duration || 120; // Set your default duration (2 mins in this case)
    const [timeLeft, setTimeLeft] = useState<number>(initialTime);
    const navigate = useNavigate();

    const timePercentage = (timeLeft / initialTime) * 100;

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0) {
            console.log('Timer ended. Fetch result or notify user.');
            navigate('/result-page');
        }
    }, [timeLeft, navigate]);

    // Dynamically change color based on the percentage of time left
    const getStrokeColor = () => {
        if (timePercentage > 50) return '#4caf50'; // Green for > 50%
        if (timePercentage > 20) return '#ffeb3b'; // Yellow for > 20%
        return '#f44336'; // Red for <= 20%
    };

    const handleContinue = () => {
        navigate('/result-page');
    };

    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <div className="timer-modal">
            <div className="timer-content">
                <div className="timer-header">
                    <h2>XAU/USD</h2>
                    <button className="close-btn" onClick={handleClose}>&times;</button>
                </div>

                <div className="timer-circle-container">
                    <svg className="timer-svg" viewBox="0 0 36 36">
                        <path
                            className="circle-bg"
                            d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                            className="circle"
                            strokeDasharray={`${timePercentage}, 100`}
                            stroke={getStrokeColor()}
                            d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                            transform="rotate(360)" // Rotate to start from the top (12 o'clock) and move clockwise
                        />

                        <text x="18" y="20.35" className="timer-text">
                            {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
                        </text>
                    </svg>
                </div>

                <div className="timer-footer">
                    <button className="btn-close" onClick={handleClose}>Close</button>
                    <button className="btn-continue" onClick={handleContinue}>Continue</button>
                </div>
            </div>
        </div>
    );
};

export default TimerPage;
