import React, { useState } from 'react';
import { FaBitcoin } from 'react-icons/fa';
import './DigitalCurrencyDeposit.css';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useLoading } from '../../../contexts/LoadingContext.tsx';

const DigitalCurrencyDeposit = () => {
  const location = useLocation();
  const [rechargeAddress, setRechargeAddress] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const { digitalCoinId } = location.state || {};

  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();

  const GoToDepositSlipClick = async () => {
    if (!rechargeAddress || !depositAmount) {
      return;
    }

    setIsLoading(true); // Start loading
    try {
      // Simulate a network request or any other async operation
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Navigate to DepositReceipt page with form data
      navigate('/DepositReceipt', { state: { rechargeAddress, depositAmount ,digitalCoinId} });
    } catch (error) {
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="deposit-container">
      <button className="back-button" onClick={handleBackClick}>←</button>
      <h2>Digital currency deposit</h2>
      <div className="currency-info">
        <div className="currency">
          <FaBitcoin className="currency-icon" />
          <span>BTC</span>
        </div>
        <div className="chain">Chain: BTC20</div>
      </div>
      <div className="recharge-addr">
        <label>Recharge Addr</label>
        <input
          type="text"
          placeholder="Enter recharge address"
          value={rechargeAddress}
          onChange={(e) => setRechargeAddress(e.target.value)}
        />
      </div>
      <div className="number-of-deposits">
        <label>Number of deposits</label>
        <input
          type="text"
          placeholder="Please enter the quantity"
          value={depositAmount}
          onChange={(e) => setDepositAmount(e.target.value)}
        />
        <div className="amount">≈ 0.00 $</div>
      </div>
      <div className="warning">
        <p>
          Please do not deposit any non-BTC assets to the above address,
          otherwise the assets will not be retrieved. After receiving your deposit, we will convert it into U.S. dollars
          based on the digital currency you deposit. After the deposit is completed, please submit the deposit voucher
          and contact our customer service staff.
        </p>
      </div>
      <button className="recharge-button" onClick={GoToDepositSlipClick} disabled={isLoading}>
        {isLoading ? 'Processing...' : 'Recharged'}
      </button>
    </div>
  );
};

export default DigitalCurrencyDeposit;
