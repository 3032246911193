import React, { ReactNode } from 'react';
import StatusBar from './StatusBar.tsx';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false });
    window.location.reload(); // Optional: You can choose to refresh the page.
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <StatusBar message="An error occurred. Please check your connection or try again." show={true} />
          <div>
            <h2>Something went wrong.</h2>
            <p>Please try again later or click the button below to retry.</p>
            <button onClick={this.handleRetry}>Retry</button>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
