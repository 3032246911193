import React, { useState } from 'react';
import TimeLimit from './TimeLimit.tsx';
import Derivatives from './Derivatives.tsx';
import BalanceDisplay from './BalanceDisplay.tsx';
import HomeCotainer from '../HomeContainer.tsx'
import './Balance.css';


const Balances = () => {
  const [activeTab, setActiveTab] = useState('timeLimit');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <HomeCotainer>
      <div className="balance-page">

        <div className='balance-header'>
          <BalanceDisplay />

          <div className="action-buttons">
            <button className="action-button">Details</button>
            <button className="action-button">Deposit</button>
            <button className="action-button">Withdraw</button>
          </div>
        </div>


        <div className="tabs">
          <button
            className={activeTab === 'timeLimit' ? 'active' : ''}
            onClick={() => handleTabClick('timeLimit')}
          >
            Time Limit
          </button>
          <button
            className={activeTab === 'derivatives' ? 'active' : ''}
            onClick={() => handleTabClick('derivatives')}
          >
            Derivatives
          </button>
        </div>
        <div className="tab-content">
          {activeTab === 'timeLimit' && <TimeLimit />}
          {activeTab === 'derivatives' && <Derivatives />}
        </div>
      </div>
    </HomeCotainer>
  );
};

export default Balances;
