import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './WithDrawMethods.css';
import { fetchAllDepositMethods } from '../../../Common/SharedApis/DespositMethods.tsx';
import { useLoading } from '../../../contexts/LoadingContext.tsx';

// Define the type for the deposit method
interface WithDrawMethods {
  depositMethodId: number;
  methodName: string;
  active: boolean;
  logoPath: string;
  coinCount: number;
}

const WithDrawMethods: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const [depositMethods, setDepositMethods] = useState<WithDrawMethods[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);  // Start loading
      try {
        const data = await fetchAllDepositMethods(); // Convert string to bigint
        setDepositMethods(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      finally {
        setIsLoading(false);  // Stop loading
      }
    };
    
    fetchData();
  }, []);

  const handleBackClick = () => {
    navigate('/home');
  };

  const handleMethodClick = (depositMethodId: number) => {
    console.log('depositMethodId',depositMethodId);
    navigate(`/CurrencyWithDraw/${depositMethodId}`);
  };

  return (
    <div className="deposit-method-container">   
      <div className="deposit-method-header">
        <button className="deposit-method-back-button" onClick={handleBackClick}>←</button>
        <h1 className="deposit-method-title">WithDraw Methods</h1>
      </div>
      {depositMethods.map((method) => (
        <div
          key={method.depositMethodId}
          className="deposit-method-item"
          onClick={() => handleMethodClick(
            method.depositMethodId
          )}
        >
          <div className="deposit-method-icon-container">
            <img
              src={`https://cryptoapi.dmmbitusa.com/${method.logoPath}`}
              alt={method.methodName}
              className="deposit-method-icon"
            />
          </div>
          <div className="deposit-method-item-text">{method.methodName}</div>
          <div className="deposit-method-arrow-container">→</div>
        </div>
      ))}
    </div>
  );
};

export default WithDrawMethods;
