import React from 'react';
import SearchBar from './SearchBar.tsx';
import CustomSlider from '../Components/Slider/CustomSlider.tsx';
import DashboardIcons from '../Components/Options/DashboardOptions.tsx';
import HomeContainer from './HomeContainer.tsx';
import './Home.css'; // Custom styles
import FeaturedSection from './Features/FeaturedSection.tsx';


function Home() {
    return (
        <HomeContainer>
             <SearchBar/>
             <CustomSlider />
             <DashboardIcons />
             <FeaturedSection />
        </HomeContainer>
    );
}

export default Home;
