import React, { useState } from 'react';
import './Order.css'; // Import the external CSS

function Order() {
    const [activeTab, setActiveTab] = useState('holding');
    const holdingList = []; // Replace with real data
    const historyList = []; // Replace with real data

    return (
        <div className="container">
            <h1 className="heading">Time Limit</h1>
            <div className="tabs">
                <button
                    onClick={() => setActiveTab('holding')}
                    className={`tab-button ${activeTab === 'holding' ? 'active' : ''}`}
                >
                    Holding List
                </button>
                <button
                    onClick={() => setActiveTab('history')}
                    className={`tab-button ${activeTab === 'history' ? 'active' : ''}`}
                >
                    History List
                </button>
            </div>

            <div className="content">
                {activeTab === 'holding' && holdingList.length === 0 && (
                    <div className="no-records">
                        <img src="clipboard-icon.png" alt="No Records" />
                        <p>No Records</p>
                    </div>
                )}

                {activeTab === 'history' && historyList.length === 0 && (
                    <div className="no-records">
                        <img src="clipboard-icon.png" alt="No Records" />
                        <p>No Records</p>
                    </div>
                )}

                {/* Add logic to display data if there are records */}
            </div>
        </div>
    );
}

export default Order;
