import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getbalance } from '../../../Common/SharedApis/DespositMethods.tsx'; // Import your API function

const BalanceDisplay = () => {
  const [balanceVisible, setBalanceVisible] = useState(true);
  const [availableBalance, setAvailableBalance] = useState(0.00);
  const [lockingBalance] = useState(250.00); // Hardcoded value
  const [totalBalance, setTotalBalance] = useState(0.00);

  const userId: string = 'string'; // Hardcoded userId as a string
  

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const balance = await getbalance(userId);
        if (balance !== null) {
          setAvailableBalance(balance);
          setTotalBalance(balance + lockingBalance); // Calculate total balance with hardcoded lockingBalance
        }
      } catch (error) {
        console.error('Failed to fetch balance', error);
      }
    };

    fetchBalance();
  }, [userId, lockingBalance]);

  const toggleBalanceVisibility = () => {
    setBalanceVisible(!balanceVisible);
  };

  return (
    <div className="balance-display">
      <div>
        <span>Total Balances (USD)</span>
        <button onClick={toggleBalanceVisibility}>
          {balanceVisible ? <FaEye /> : <FaEyeSlash />}
        </button>
      </div>
      <div>{balanceVisible ? totalBalance.toFixed(2) : '***'}</div> {/* Display total balance */}
      <div>
        <span>Available</span>
        <span>{balanceVisible ? availableBalance.toFixed(2) : '***'}</span>
      </div>
      <div>
        <span>Locking</span>
        <span>{balanceVisible ? lockingBalance.toFixed(2) : '***'}</span>
      </div>
    </div>
  );
};

export default BalanceDisplay;
