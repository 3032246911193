import React, { useEffect, useState } from 'react';
import { FaRegCircle, FaDollarSign, FaHeadphones, FaCoins, FaClock, FaFileContract, FaDownload, FaTh } from 'react-icons/fa';
import '../Options/DashboardOptions.css';
import StatusBar from '../../../contexts/StatusBar.tsx';

type DeferredPromptType = Event & {
  prompt: () => void;
  userChoice: Promise<{ outcome: string }>;
};

const DashboardIcons = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<DeferredPromptType | null>(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [showStatusBar, setShowStatusBar] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  
  const isAppInstalled = () => window.matchMedia('(display-mode: standalone)').matches;

  const handleDownloadClick = () => {
    if (deferredPrompt && !isAppInstalled()) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      setStatusMessage('App installation is not supported or already installed. Please install the app from the browser menu.');
      setShowStatusBar(true);
    }
  };

  const handleCloseStatusBar = () => {
    setShowStatusBar(false);
    setStatusMessage('');
  };

  return (
    <>
      <StatusBar message={statusMessage} show={!!statusMessage} onClose={handleCloseStatusBar} />

      <div className="dashboard-icons">
        <a href="DepositMethods" rel="noopener noreferrer" className="icon">
          <FaRegCircle className="icon-svg" />
          <span>Deposit</span>
        </a>
        <a href="WithdrawMethods" rel="noopener noreferrer" className="icon">
          <FaDollarSign className="icon-svg" />
          <span>Withdraw</span>
        </a>
        <a href="serviceCenter" rel="noopener noreferrer" className="icon">
          <FaHeadphones className="icon-svg" />
          <span>Online service</span>
        </a>
        <div className="icon">
          <FaCoins className="icon-svg" />
          <span>New Coins</span>
          <div className="badge">NEW</div>
        </div>
        <div className="icon">
          <FaClock className="icon-svg" />
          <span>Time Limit</span>
          <div className="badge hot">HOT</div>
        </div>
        <div className="icon">
          <FaFileContract className="icon-svg" />
          <span>Contract</span>
        </div>
        <div className="icon" onClick={handleDownloadClick}>
          <FaDownload className="icon-svg" />
          <span>Download APP</span>
        </div>
        <div className="icon">
          <FaTh className="icon-svg" />
          <span>More</span>
        </div>
      </div>
    </>
  );
};

export default DashboardIcons;
