import React from 'react';

function Header() {
    return (
        <div>
            {/* <h1>Headeer</h1> */}
            {/* Add your home page content here */}
        </div>
    );
}

export default Header;
