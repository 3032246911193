import React from 'react';
import { FaHeadset } from 'react-icons/fa';
import { FaTelegramPlane } from 'react-icons/fa';
import './ServiceCenter.css';
import HomeContainer  from  '../../Dashboard/HomeContainer.tsx'

const ServiceCenter = () => {
  const handleTelegramClick = () => {
    window.open('https://t.me/yourtelegramlink', '_blank');
  };

  return (
    <HomeContainer>
    <div className="service-center">
      <h2>Service Center</h2>
      <div className="service-item">
        <FaHeadset className="icon" />
        <span>Online service</span>
      </div>
      <div className="service-item" onClick={handleTelegramClick}>
        <FaTelegramPlane className="icon" />
        <span>Telegram</span>
      </div>
    </div>
  </HomeContainer>
  );
};

export default ServiceCenter;
