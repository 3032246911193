import React from 'react';

const TimeLimit = () => {
  return (
    <div>
      <p>Daily PNL(USD)</p>
      <p>+0.00</p>
      <p>Security Deposit(USD)</p>
      <p>0.00</p>
      <p>Holding quantity(0)</p>
      <p>No Records</p>
    </div>
  );
};

export default TimeLimit;
