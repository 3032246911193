import apiBaseUrl from '../ApiBaseUrl.tsx';

const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error fetching data: ${response.statusText} - ${errorText}`);
    }
    return await response.json();
};

// Login API
export const login = async (model: { userName: string; password: string }) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model),
        });

        if (!response.ok) {
            const errorText = await response.text();
            const errorMessage = `Error ${response.status}: ${errorText || response.statusText}`;
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(errorMessage);
        } else {
            const data = await response.json();
            console.log('Login successful:', data);

            // Save token and expiration time
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('tokenExpiration', data.expiration);
            localStorage.setItem('userName', data.userName);
            localStorage.setItem('userId', data.userId);
            return data;
        }
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};

// Register API
export const register = async (model: {
    Email: string;
    Password: string;
    ConfirmPassword: string;
    PhoneNumber: string;
    FirstName: string;
    LastName: string;
    CountryId: number;
}) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/Register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(`Error registering user: ${response.statusText}`);
        } else {
            const data = await handleResponse(response);
            console.log('Registration successful:', data);
            return data;
        }
    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
};

// Forgot Password API
export const forgotPassword = async (email: string) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/ForgotPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Email: email }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(`Error sending forgot password email: ${response.statusText}`);
        } else {
            console.log('Forgot password email sent successfully');
            return true;
        }
    } catch (error) {
        console.error('Error during forgot password:', error);
        throw error;
    }
};

// Reset Password API
export const resetPassword = async (model: { Email: string; Token: string; NewPassword: string }) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/ResetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model),
        });

        if (!response.ok) {
            const errorText = await response.text();
            const errorMessage = `Error ${response.status}: ${errorText || response.statusText}`;
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(errorMessage);
        } else {
            console.log('Password reset successfully');
            return true;
        }
    } catch (error) {
        console.error('Error during reset password:', error);
        throw error;
    }
};

// Change Password API
export const changePassword = async (model: { OldPassword: string; NewPassword: string }) => {
    const authToken = localStorage.getItem('authToken'); // Get the token from local storage
    if (!authToken) {
        throw new Error('No authentication token found');
    }

    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/ChangePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`, // Include the token in the header
            },
            body: JSON.stringify(model),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(`Error changing password: ${errorText || response.statusText}`);
        } else {
            console.log('Password changed successfully');
            return true;
        }
    } catch (error) {
        console.error('Error during change password:', error);
        throw error;
    }
};

// api.ts
export const getCountryList = async (countryId: number) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Country/GetAllCountries?countryId=${countryId}`);
        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
            return [];
        }
        return await response.json(); // Parse JSON
    } catch (error) {
        console.error('Error fetching data:', error);
        return []; // Return empty array on error
    }
};

export const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiration');
    window.location.href = '/login'; // Redirect to login page
};
