import React from 'react';
import TradingViewChart from '../Components/Charts/TradingViewChart.tsx';

function Trade() {
    return (
        <div>
           <TradingViewChart />
        </div>
    );
}

export default Trade;
