import React, { useState } from 'react';
import NavigationMenu from '../Components/Navigation/Navigation.tsx'; // Corrected import path
import './SearchBar.css'; // Import the CSS file

const SearchBar: React.FC = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleSearchClick = () => {
        setIsFullScreen(true);
    };

    const handleCloseClick = () => {
        setIsFullScreen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleChatIconClick = () => {
        window.location.href = "/chat";
    };

    return (
        <div className={`search-container ${isFullScreen ? 'full-screen' : ''}`}>
            <img 
                src="../SearchImages/profile.png" 
                alt="Logo" 
                className="logo" 
                onClick={toggleMenu}
            />
            <div className="search-bar">
                <input 
                    type="text" 
                    placeholder="Search For Products" 
                    className="search-input"
                    onClick={handleSearchClick} 
                />
                {isFullScreen && <button className="close-btn" onClick={handleCloseClick}>X</button>}
            </div>
     
            {isMenuOpen && <NavigationMenu toggleMenu={function (): void {
                throw new Error('Function not implemented.');
            } } isOpen={false} />}
            <img src="../SearchImages/chat.png" className="chat-icon" onClick={handleChatIconClick} />
            <img src="../SearchImages/flag.jpeg" className="flag-icon" />
            {isFullScreen && <button className="close-btn" onClick={handleCloseClick}>X</button>}

            <NavigationMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </div>
    );
};

export default SearchBar;
