import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { login } from '../Common/SharedApis/Auth.tsx';
import { useLoading } from '../contexts/LoadingContext.tsx';
import { toast } from 'react-toastify';
import { useSignalR } from '../contexts/SignalRProvider.tsx';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons

const LoginPage = () => {
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();
  const startConnection = useSignalR(); // Access startConnection method

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const loginData = {
        userName: userName,
        password: password,
      };

      console.log('Login data:', loginData);

      const result = await login(loginData);

      console.log('Login successful:', result);
      startConnection?.startConnection();
      navigate('/home');
    } catch (error) {
      const errorMessage = error.message || 'An unexpected error occurred.';
      setError(errorMessage);
      console.error('Login error:', error);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header"></div>
      <h2 className="login-title">Welcome To Login</h2>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="input-group">
          <label>Email or Mobile Number</label>
          <input
            type="text"
            placeholder="Enter Email or Mobile Number"
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group" style={{ position: 'relative' }}>
          <label>Password</label>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ paddingRight: '40px' }} // Add padding to the right for the icon
          />
          <button
            type="button"
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              right: '10px',
              top: '74%',
              transform: 'translateY(-50%)',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: '0',
              fontSize: '15px', // Adjust the size of the icon
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button">Login</button>
      </form>
      <div className="auth-links">
        <a href="/forget" className="forgot-password-link">Forgot Password</a>
        <a href="/SignUp" className="forgot-password-link">Sign Up</a>
      </div>

      <div className="user-agreement">
        <span>Continue Is Agree</span>
        <a href="/user-agreement" className="user-agreement-link">User Agreement</a>
      </div>
    </div>
  );
};

export default LoginPage;
