import React from 'react';

const Derivatives = () => {
  return (
    <div>
      <p>Derivatives content here</p>
    </div>
  );
};

export default Derivatives;
