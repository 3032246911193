// config.ts
const ApiBaseUrl = {
     apiBaseUrl: 'https://cryptoapi.dmmbitusa.com/api',
   //   apiBaseUrl:'https://localhost:44379/api',
      //apiBaseUrl: 'https://cryptoapi.dmmbitusa.com/api',
     //apiBaseUrl:'https://localhost:44379/api',

   //   signalRBaseUrl: 'https://localhost:44379',
      signalRBaseUrl: 'https://cryptoapi.dmmbitusa.com',
  };
  
  export default ApiBaseUrl;
  