import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { currencyWithDraw } from '../../../Common/SharedApis/DespositMethods.tsx'; // Adjust the path as necessary
import './CurrencyWithDraw.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import { useLoading } from '../../../contexts/LoadingContext.tsx'; // Import useLoading


const CurrencyWithDraw: React.FC = () => {
  const navigate = useNavigate();
  const [withdrawAmount, setWithdrawAmount] = useState(200); // Default value for the withdrawal amount
  const { isLoading, setIsLoading } = useLoading(); // Destructure isLoading and setIsLoading from useLoading

  const handleBackClick = () => {
    navigate('/home');
  };

  const handleSubmit = async () => {
    const data = {
      requestUserId: 'yourUserId', // Replace with actual user ID
      withDrawAmount: withdrawAmount,
      withDrawToAddress: 'yourWalletAddress', // Replace with actual wallet address
      statusId: 1, // Example status ID
      isActive: true // Example of hardcoded isActive field
    };

    console.log('Submitting data:', data); // Log data before sending

    setIsLoading(true); // Set loading state to true

    try {
      const responseText = await currencyWithDraw(data);
      console.log('API Response:', responseText); // Log the response from API
      toast.success('Withdrawal request submitted successfully!');
      setWithdrawAmount(0); // Clear the withdrawal amount field after successful submission
    } catch (error) {
      console.error('Error creating withdrawal:', error);
      toast.error('Failed to submit withdrawal request. Please try again.');
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
    } finally {
      setIsLoading(false); // Set loading state to false after the operation
    }
  };

  return (
    <div className="withdrawal-form">
      <h1>Digital currency withdrawal</h1>
      
      <Link to="/pocketAddr" className="currency-selector">
        <div className="currency-selector">
          <div className="currency-icon">
            <img src="https://via.placeholder.com/40" alt="BTC Icon" />
          </div>
          <div className="currency-details">
            <span>BTC(BTC20)</span>
            <span>Chain: (BTC20)</span>
          </div>
        </div>
      </Link>
      
      <div className="available-balance">
        <span>Available</span> <span>$ 0.00</span>
      </div>

      <div className="amount-input">
        <label htmlFor="withdrawal-amount">Withdrawal amount</label>
        <span className="amount-range">MIN: 100.00 $, MAX: 5,000,000.00 $</span>
        <input
          type="number"
          id="withdrawal-amount"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(parseFloat(e.target.value))}
          disabled={isLoading} // Disable input when loading
        />
        <span className="btc-equivalent">≈ 0.00331212 (BTC)</span>
      </div>

      <div className="charges-info">
        <div className="charges">
          <span>Charges</span>
          <span>25.00 $ ≈ 0.00041401 (BTC)</span>
        </div>
        <p>
          For your current withdrawal, the handling fee will be deducted from your current withdrawal amount, and the actual amount shall be subject to the actual amount received.
        </p>
      </div>

      <div className="submit-button">
        <button type="submit" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default CurrencyWithDraw;
