import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LoadingProvider } from './contexts/LoadingContext.tsx';
import ToastProvider from './contexts/ToastProvider.tsx';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js'; // Import the service worker registration

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <LoadingProvider>
     <ToastProvider>
           <App />
     </ToastProvider>
     </LoadingProvider>

   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
// Register the service worker
serviceWorkerRegistration.register();
