import React from 'react';
import './Settings.css';
import { logout } from '../../Common/SharedApis/Auth.tsx';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { useLoading } from '../../contexts/LoadingContext.tsx';

const Settings = () => {

  const navigate = useNavigate(); // Initialize useNavigate
  const { isLoading, setIsLoading } = useLoading();
  const handleLogout = () => {
    setIsLoading(true);
    logout(); // Call the logout function
    setIsLoading(false);
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <div className="container">
      <div className="header">
        <span className="back-icon">←</span>
        <h1 className="title">Settings</h1>
        {/* <img src={avatar} alt="Avatar" className="avatar" /> */}
      </div>
      <div className="section">
        <div className="row">
          <span>Avatar</span>
        </div>
        <div className="row">
          <span>Share Code</span>
          <span>186094</span>
        </div>
        <div className="row">
          <span>Language</span>
          <span>English</span>
        </div>
        <div className="row">
          <span>Funds</span>
          <span>USD</span>
        </div>
        <div className="row">
          <span>Version</span>
          <span>v2.1.4-akep-1681970571405</span>
        </div>
      </div>
      <button className="logout-button" onClick={handleLogout}>Logout</button> {/* Handle button click */}
   
    </div>
  );
}

export default Settings;
