// Sliders.tsx
import React from 'react';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Slider.css'; // Custom styles

const CustomSlider: React.FC = () => {
    const settings: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Add this line
        autoplaySpeed: 2000, // Add this line
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="slide">
                <img src="../SliderImages/three.jpeg" alt="Slider 1" />
               
                </div>
                <div className="slide">
                    <img src="../SliderImages/two.jpeg" alt="Slider 2" />
                    
                </div>
                <div className="slide">
                    <img src="../SliderImages/two.jpeg" alt="Slider 3" />
                    
                </div>
            </Slider>
        </div>
    );
};

export default CustomSlider;
