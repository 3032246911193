import React, { createContext, useState, useContext, Dispatch, SetStateAction } from 'react';
import Loader from '../App/Components/Shared/Loader/Loader.tsx';

// Define the shape of your context
type LoadingContextType = {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

// Create the context with a default value
const LoadingContext = createContext<LoadingContextType>({
    isLoading: false,
    setIsLoading: () => {} // no-op function for default value
});

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
            <Loader isLoading={isLoading} />
        </LoadingContext.Provider>
    );
};
