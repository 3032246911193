import React, { useEffect ,useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './Login/Loginpage.tsx';
import SignUp from './SignUp/SignUp.tsx'; 
import Forgot from './Accounts/ForgotPassword/Forgot.tsx'; 
import ResetPassword from './Accounts/ResetPassword/ResetPassword.tsx'; 

import './App.css';
import DashboardLayout from './Layout/DashboardLayout.tsx'; 
import { SignalRProvider } from './contexts/SignalRProvider.tsx';
import ProtectedRoute from './contexts/ProtectedRoute.tsx';
import ChangePassword from './Accounts/ChangePassword/ChangePassword.tsx';
import NotFoundPage from './App/Dashboard/NotFoundPage.tsx'; 
import useOnlineStatus from './contexts/useOnlineStatus.tsx'; 
import StatusBar from './contexts/StatusBar.tsx'; 
import { toast } from 'react-toastify';
import ErrorBoundary from './contexts/ErrorBoundary.tsx';

function App() {
  const isOnline = useOnlineStatus();  // Use the custom hook to check online status
  const [showStatusBar, setShowStatusBar] = useState(true);
  const [statusMessage, setStatusMessage] = useState('No internet connection detected. Please check your network settings.');
  const handleCloseStatusBar = () => {
    setShowStatusBar(false);
  };

  return (
    <Router>
       {/* Add the StatusBar at the top of your app */}
       <StatusBar message={statusMessage} show={!isOnline}  onClose={handleCloseStatusBar}/>
       <SignalRProvider>
       <ErrorBoundary> 
       <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage />} />  
          <Route path="/signup" element={<SignUp />} />  
          <Route path="/forget" element={<Forgot />} />  
          <Route path="/change-password" element={<ChangePassword />} />  
          <Route path="/reset-password" element={<ResetPassword />} />
             {/* Catch-all route for public NotFoundPage */}
         
           {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
              <Route path="/*" element={<DashboardLayout />} />  
              {/* <Route path="*" element={<NotFoundPage />} />             */}
          </Route>  
          <Route path="*" element={<NotFoundPage />} />
        
       </Routes>
       </ErrorBoundary>
       </SignalRProvider>
        
    </Router>
  );
}

export default App;
