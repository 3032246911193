import apiBaseUrl from '../ApiBaseUrl.tsx';

// Utility function to handle API responses
const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error fetching data: ${response.statusText} - ${errorText}`);
    }
    return await response.json();
};

var token = localStorage.getItem('authToken')?.toString();
// Send a message to admin
export const sendMessageToAdmin = async (message: { SenderId: string; Message: string }) => {
  
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Chat/SendMessageToAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(message),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error sending message to admin: ${response.statusText} - ${errorText}`);
        } else {
            console.log('Message sent to admin successfully');
            return await response.json();
        }
    } catch (error) {
        console.error('Error during send message to admin:', error);
        throw error;
    }
};

// Send a message to a user
export const sendMessageToUser = async (message: { SenderId: string; ChatRoomId: number; Message: string }) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Chat/SendMessageToUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(message),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error sending message to user: ${response.statusText} - ${errorText}`);
        } else {
            console.log('Message sent to user successfully');
            return await response.json();
        }
    } catch (error) {
        console.error('Error during send message to user:', error);
        throw error;
    }
};

// Mark all messages as seen in a chat room
export const markAllMessagesAsSeen = async (chatRoomId: number) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Chat/MarkAllMessagesAsSeen`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(chatRoomId),
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error marking messages as seen: ${response.statusText} - ${errorText}`);
        } else {
            console.log('Messages marked as seen successfully');
            return await response.json();
        }
    } catch (error) {
        console.error('Error during mark all messages as seen:', error);
        throw error;
    }
};

// Fetch messages for a specific chat room with pagination
export const getMessages = async (chatRoomId: number, pageNumber: number = 1, pageSize: number = 20) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Chat/GetMessages?chatRoomId=${chatRoomId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error fetching messages: ${response.statusText} - ${errorText}`);
        } else {
            return await response.json();
        }
    } catch (error) {
        console.error('Error during fetching messages:', error);
        throw error;
    }
};

export const getChatRoomId = async (userId: string) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Chat/GetChatRoomId?userId=${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error getting chat room ID: ${response.statusText} - ${errorText}`);
        } else {
            return await response.json();
        }
    } catch (error) {
        console.error('Error during getting chat room ID:', error);
        throw error;
    }
};