import React, { useEffect, useState } from 'react';
import { useSignalR } from '../../contexts/SignalRProvider.tsx'; // Adjust the import path as needed
import './ResultPage.css'; // Import your CSS file if needed

const ResultPage: React.FC = () => {
  
    const [message, setMessage] = useState<string | null>(null);
    const signalRContext = useSignalR();

    useEffect(() => {
        if (signalRContext && signalRContext.connection) {
          const { connection } = signalRContext;
    
          connection.on('TradeResult', (type: string, receivedMessage: string) => {
            setMessage(`${type}: ${receivedMessage}`);
          });
    
          return () => {
            connection.off('TradeResult');
          };
        }
      }, [signalRContext]);

    return (
        <div className="result-page">
            <h1>Trade Result</h1>
            {message ? (
                <div className="result-message">
                    <p>{message}</p>
                </div>
            ) : (
                <p>Waiting for trade result...</p>
            )}
        </div>
    );
};

export default ResultPage;
