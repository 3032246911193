import React, { useEffect, useState } from 'react';
import './TradingViewChart.css';

interface TradingViewChartProps {
  selectedSymbol: string | null;
  onValueUpdate: (latestValue: number) => void;
}

const TradingViewChart: React.FC<TradingViewChartProps> = ({ selectedSymbol, onValueUpdate }) => {
  const [chart, setChart] = useState<any>(null);

  useEffect(() => {
    // Load TradingView script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    script.onload = () => {
      if (window.TradingView) {
        try {
          // Initialize TradingView widget
          const newChart = new window.TradingView.widget({
            container_id: 'tradingview_chart',
            width: '100%',
            height: 730,
            symbol: selectedSymbol ? `BINANCE:${selectedSymbol.toUpperCase()}USDT` : 'BINANCE:BTCUSDT',
            interval: '1',
            timezone: 'Etc/UTC',
            theme: 'dark',
            style: '1',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            allow_symbol_change: true,
            details: true,
            hotlist: true,
            calendar: true,
          });
          setChart(newChart);
        } catch (error) {
          console.error('Error initializing TradingView widget:', error);
        }
      } else {
        console.error('TradingView object not found.');
      }
    };
    script.onerror = (error) => {
      console.error('Error loading TradingView script:', error);
    };
    document.body.appendChild(script);

    // WebSocket for real-time data
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${selectedSymbol ? selectedSymbol.toLowerCase() : 'btcusdt'}@trade`);

    ws.onopen = () => {
      console.log('WebSocket connection established.');
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        const latestPrice = parseFloat(data.p);
        console.log('Received data from WebSocket:', data);
        console.log('Latest Price:', latestPrice);
        onValueUpdate(latestPrice);
      } catch (error) {
        console.error('Error processing WebSocket message:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed.');
    };

    // Cleanup on unmount or when selectedSymbol changes
    return () => {
      if (ws) ws.close();
      if (script.parentNode) script.parentNode.removeChild(script);
    };
  }, [selectedSymbol, onValueUpdate]);

  return (
    <div className='trade-container'>
      <div id="tradingview_chart"></div>
    </div>
  );
};

export default TradingViewChart;
