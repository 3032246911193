import apiBaseUrl from '../ApiBaseUrl.tsx';

const handleResponse = async (response: Response) => {
    if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
};

export const createTrade = async (trade: any) => {
    var token = localStorage.getItem('authToken')?.toString();
    console.log('token',token);
    console.log('model',trade);
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Trade/CreateTrade`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(trade),
        });
        const data = await handleResponse(response);
        console.log('Trade created successfully:', data);
        return data;
    } catch (error) {
        console.error('Error creating trade:', error);
        throw error;
    }
};
