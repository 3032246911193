import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaChartLine, FaExchangeAlt, FaClipboardList, FaWallet } from 'react-icons/fa';
import './BottomNav.css';

const BottomNav: React.FC = () => {
    return (
        <div className="bottom-nav">
            <NavLink
                to="home"
                end
                className={({ isActive }) => (isActive ? 'active' : '')}
            >
                <FaHome />
                <span>Home</span>
            </NavLink>
            <NavLink
                to="market"
                className={({ isActive }) => (isActive ? 'active' : '')}
            >
                <FaChartLine />
                <span>Market</span>
            </NavLink>
            <NavLink
                to="trade"
                className={({ isActive }) => (isActive ? 'active' : '')}
            >
                <FaExchangeAlt />
                <span>Trade</span>
            </NavLink>
            <NavLink
                to="order"
                className={({ isActive }) => (isActive ? 'active' : '')}
            >
                <FaClipboardList />
                <span>Order</span>
            </NavLink>
            <NavLink
                to="balances"
                className={({ isActive }) => (isActive ? 'active' : '')}
            >
                <FaWallet />
                <span>Balances</span>
            </NavLink>
        </div>
    );
};

export default BottomNav;
